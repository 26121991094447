import './style.scss';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { isMobile, isIOS, isMobileOnly } from 'mobile-device-detect';
import { Button, Card, Col, Row } from 'reactstrap';
import { Chip, MenuItem, Select } from '@material-ui/core';
import Carousel from 'react-slick';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Rating from 'react-rating';
import { Link, withRouter } from 'react-router-dom';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FormattedMessage } from 'react-intl';
import Popover from '@material-ui/core/Popover';
import safeAreaInsets from 'safe-area-insets';
import Icon from '../../components/Icon';
import FancyBox from '../../components/fancybox';
import PageWrap from '../../components/page-wrap';
import RatingProgress from '../../components/RatingProgress';
import CigarSessions from '../../components/cigar-sessions';
import CigarStrengthGauge from '../../components/cigar-strength';
import HumidorEntryEditor from '../HumidorEntryEditor';
import SessionEditor from '../SessionEditor';
import TabPanel from '../../components/TabPanel';
import ShareIntent from '../../utils/shareUtils';
import { addToast as actionAddToast } from '../../actions';
import BottomSheet from '../../components/bottom-sheet';
import { config } from '../../settings';
import CigarCompositionChart from '../../components/cigar-composition-chart';
import CigarEditor from '../CigarEditor';
import ShareItem from '../../models/ShareItem';
import { saveItem, unsaveItem } from '../SavedItems/helper';
import { addOrUpdateHumidorEntry as updateEntryAction } from '../Humidor/actions';
import strings from '../../../config/strings';
import { mergeSessions, requestCigarSessions as requestCigarSessionsAction } from '../SessionEditor/actions';
import SessionNotesCard from '../../components/SessionNotesCard';
import Product from '../../models/Product';
import MediaGrid from '../../components/media-grid';
import { selectAllUserSmokeSessions } from '../SessionEditor/selectors';
import { CigarStore, SettingsStore } from '../../stores';
import Cigar from '../../models/Cigar';
import Placeholder from '../../../config/placeholder.config';
import PlaceholderDark from '../../../config/placeholder-dark.config';
import PageMeta from '../../components/PageMeta';
import { Resize } from '../../utils/imageUtils';
import { dateFromTimestamp, isToday } from '../../utils/formatting';
import { language, messages } from '../../utils/localeUtils';
import ProductCardList from '../../components/ProductCardList';
import { redirectAuth } from '../../utils/redirect';
import hashids from '../../utils/hashIds';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <Icon name="chevron-right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <Icon name="chevron-left" />
    </div>
  );
}

const carouselSettings = {
  className: 'slider variable-width',
  dots: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: true,
  // arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const insetBottom = safeAreaInsets.bottom;

let updatedMeta = false;
function CigarDetail(props) {
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  // FIXME These might be better as separate items - take the time to split them out
  const [state, setState] = useState({
    activeTab: 0,
    activeSecondaryTab: 'all', // TODO Should depend on available items - priority should be yours, friends, and then all (see Untappd)
    showProfileUpload: false, // TODO Only if not mobile
    showBasicInfoEditor: false,
    showContactInfoEditor: false,
    showMoreInfoEditor: false,
    loading: true,
  });
  const [cigar, setCigar] = useState({});
  const [images, setImages] = useState({});
  const [vitolas, setVitolas] = useState([]);
  const [selectedVitola, setSelectedVitola] = useState(null);
  const [products, setProducts] = useState([]);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showHumidorEntryEditor, setShowHumidorEntryEditor] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [userSessions, setUserSessions] = useState(null); // FIXME Use Pullstate for this
  const savedCigars = CigarStore.useState((s) => s.saved);
  const [itemSaved, setItemSaved] = useState(false);
  const favoriteCigars = CigarStore.useState((s) => s.favorites);
  const [itemFavorited, setItemFavorited] = useState(false);
  const [addActionBtn, setAddActionBtn] = useState(null);
  const [showActionPopover, setShowActionPopover] = useState(false);
  const [metaDescription, setMetaDescription] = useState(undefined);
  const [metaImage, setMetaImage] = useState(undefined);
  const [metaReviews, setMetaReviews] = useState(undefined);
  const [detailedRatings, setDetailedRatings] = useState({});
  const [hasDailySession, setHasDailySession] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const allSessions = CigarStore.useState((s) => s.sessions);

  const moreOptions = [{
    content: strings.add_to_humidor,
    value: 'add',
    onClick: () => toggleHumidorEntryEditor(),
  }, {
    content: strings.report_incorrect_data,
    value: 'report',
    onClick: () => {
      const { auth } = props;
      const user = auth.user || {};
      if (user && user.id) {
        setShowReportModal(true);
      } else {
        redirectAuth(`${window.location.href}?action=report&itemId=${cigar.id}`);
      }
    },
  }, {
    content: 'Embed',
    icon: 'code',
    value: 'embed',
    onClick: () => {
      window.open(`${config.embedEndPoint}?query=${config.appUrl}/cigars/${hashids.encode(cigar.id)}`, '_blank');
    },
  }];

  const getCigarDetail = (cigarId) => {
    console.log(`Getting cigar details for ${cigarId}`);
    axios.get(`${config.apiEndPoint}/cigars/${cigarId}`).then((response) => {
      const cigarDetails = response.data;
      console.log('Got cigar:');
      console.log(cigarDetails);

      if (window.analytics) {
        window.analytics.page('Cigar Details', null, {
          item_id: cigarDetails.id,
          item_type: 'cigars',
          name: cigarDetails.full_name,
          brand_id: cigarDetails.brand_id,
        });
      }

      const productImages = [];
      if (cigarDetails.products) {
        for (let i = 0; i < cigarDetails.products.length; i++) {
          const product = cigarDetails.products[i];
          if (product.images && product.images.length) {
            for (let j = 0; j < product.images.length; j++) {
              const image = product.images[j];
              let imageUrl;
              if (image.url.indexOf('http') === -1) {
                imageUrl = `https://${image.url}`;
              } else {
                imageUrl = image.url;
              }
              console.log('Adding image url:');
              console.log(imageUrl);
              productImages[imageUrl] = {
                owner: {
                  image: '',
                  name: '',
                },
                type: 'image',
                src: imageUrl,
                // TODO Any other fields? Price and buy link? "Sponsored" indicator when ads are integrated?
              };
            }
          }
        }
      }

      setState({
        ...state,
        loading: false,
      });
      setCigar(cigarDetails);
      setImages([...images, productImages]);
      setVitolas(vitolas);
      // FIXME We will need to ensure that the products get filtered with the vitola - only show products containing
      //  the selected vitola
      setProducts(cigarDetails.products.map((product) => new Product(product)));
    }).catch((err) => {
      console.error(err);
      setState({
        ...state,
        loading: false,
      });
    });
  };

  // Adds images from sessions to state `images` object
  const addSessionImages = (cigarId) => {
    axios.get(`${config.apiEndPoint}/cigars/${cigarId}/images`, {
      params: selectedVitola && selectedVitola.id ? { vitola_id: selectedVitola.id } : null,
    }).then(({ data: cigarMedia }) => {
      console.log('Cigar images:');
      console.log(cigarMedia);
      const images = {};
      cigarMedia.forEach((media) => {
        images[media.image_url] = {
          owner: {
            image: media.user.image_url,
            name: media.user.alias,
          },
          type: media.media_type,
          src: media.media_url,
        };
      });
      console.log(images);
      setImages(images);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getCigarRatings = (cigarId) => {
    axios.get(`${config.apiEndPoint}/cigars/${cigarId}/ratings`, {
      params: selectedVitola && selectedVitola.id ? { vitola_id: selectedVitola.id } : null,
    }).then((response) => {
      console.log('Got cigar ratings');
      console.log(response.data);
      setDetailedRatings(response.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const getUserSessions = (cigarId) => {
    const { auth } = props;
    axios.get(`${config.apiEndPoint}/users/${auth.user.id}/cigars/${cigarId}/sessions`).then((response) => {
      console.log('User sessions:');
      console.log(response.data);
      CigarStore.update((s) => {
        mergeSessions(response.data, s);
      });
    }).catch((err) => {
      console.log('Could not get user sessions');
      console.log(err);
    });
  };

  const getVitolas = (cigarId) => {
    axios.get(`${config.apiEndPoint}/cigars/${cigarId}/vitolas`).then((response) => {
      const vitolaList = response.data;
      if (vitolaList) {
        setVitolas(vitolaList);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getProducts = (cigarId) => {
    axios.get(`${config.shopEndPoint}/products/cigars/${cigarId}`).then((response) => {
      const productList = response.data;
      if (productList) {
        setProducts(productList.map((item) => new Product(item)));
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getCigarParam = () => {
    const { match: { params } } = props;
    const { cigarId } = params;
    let id = cigarId;
    if (cigarId.indexOf('?') !== -1) {
      // eslint-disable-next-line prefer-destructuring
      id = cigarId.split('?')[0];
    }
    id = id.replace(/[\W_]+/g, '')
    console.log(`Got cigar ID from route: ${id}`);
    console.log(`Hash ID decoded content: ${hashids.decode(id)}`);
    console.log(`Hash ID decoded ID: ${hashids.decode(id)}`);
    return hashids.decode(id);
  };

  useEffect(() => {
    if (selectedVitola) {
      vitolas.forEach((vitola) => {
        if (parseInt(selectedVitola.id) === parseInt(vitola.id)) {
          setSelectedVitola(vitola);
        }
      });
    }
  }, [vitolas]);

  useEffect(() => {
    const { auth } = props;
    const decodedId = getCigarParam();
    if (window.analytics) {
      const { user } = auth;
      if (decodedId && user && user.id) {
        window.analytics.track('Cigar Views', {
          cigar_id: decodedId,
          user_id: user.id,
        });
      }
    }
    // FIXME Better approach than this?
    let foundCigar = false;
    console.log('Getting cigar from location state...');
    console.log(props.location);
    console.log(props.location && props.location.state);
    if (props.location && props.location.state && props.location.state.cigar) {
      foundCigar = true;
      setState({
        ...state,
        loading: false,
      });
      setCigar(props.location.state.cigar);
    }

    if (decodedId) {
      console.log(`Decoded ID: ${decodedId}`);
      if (!foundCigar) {
        // FIXME Use reselect to grab the cigar from existing storage, if available
        getCigarDetail(decodedId);
      }

      addSessionImages(decodedId);
      getCigarRatings(decodedId);
      getVitolas(decodedId);
      getProducts(decodedId);

      // if (auth && auth.user) {
      //   console.log('Getting user sessions...');
      //   getUserSessions(decodedId);
      // }
    }
  }, [props.match.params.cigarId]);

  useEffect(() => {
    const user = props.auth && props.auth.user;
    if (cigar.id && allSessions && user) {
      const sessions = allSessions[cigar.id];
      if (sessions) {
        setUserSessions(sessions.filter((session) => session.scan.user_id === user.id));
        // FIXME This will pull them all again - better way to include them in PullState so we can just unshift new ones
        //  in the editor when new ones are added?
        addSessionImages(cigar.id);
      }
    }
  }, [allSessions]);

  useEffect(() => {
    if (userSessions) {
      setHasDailySession(false);
      userSessions.forEach((session) => {
        if (isToday(dateFromTimestamp(session.timestamp))) {
          setHasDailySession(true);
          setSelectedSession(session);
        }
      });
    }
  }, [userSessions]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const vitolaId = urlParams.get('vitola');
    const smokeNowAction = !!urlParams.get('action') && urlParams.get('action') === 'smokenow';
    const reportAction = !!urlParams.get('action') && urlParams.get('action') === 'report';
    const showProductsTab = urlParams.get('tab') === 'products';
    const sessionId = urlParams.get('session_id');

    // FIXME What was this in here for? This is to scroll down to the session in the list?
    // if (sessionId) {
    //   setSelectedSessionId(hashids.decode(sessionId)[0]);
    // }

    setSelectedVitola(null);

    if (vitolaId) {
      console.log('Setting selected vitola: ', vitolaId);
      if (vitolas && vitolas.length) {
        vitolas.forEach((vitola) => {
          if (parseInt(vitola.id) === parseInt(vitolaId)) {
            setSelectedVitola(vitola);
          }
        });
      } else {
        setSelectedVitola({ id: vitolaId });
      }
    }

    if (smokeNowAction) {
      setShowSessionModal(true);
    }

    if (reportAction) {
      setShowReportModal(true);
    }

    if (showProductsTab) {
      const $tabs = $('#cigar-products-card');
      if ($tabs.offset()) {
        setTimeout(() => {
          toggleTab(null, 1);
          $('html, body').animate({
            scrollTop: $tabs.offset().top,
          }, 500);
        }, 900);
      }
    }
  }, [window.location.search]);

  useEffect(() => {
    const cigarId = getCigarParam();
    console.log(savedCigars);
    if (cigarId) {
      const saved = savedCigars.indexOf(parseInt(cigarId)) !== -1;
      setItemSaved(saved);
    }
  }, [savedCigars, props.match.params.cigarId]);

  useEffect(() => {
    const cigarId = getCigarParam();
    if (cigarId) {
      const favorite = favoriteCigars.indexOf(parseInt(cigarId)) !== -1;
      setItemFavorited(favorite);
    }
  }, [favoriteCigars, props.match.params.cigarId]);

  useEffect(() => {
    const cigarId = getCigarParam();
    addSessionImages(cigarId);
    getCigarRatings(cigarId);
  }, [selectedVitola]);

  // useEffect(() => {
  //   // Listen to changes to the userSessions and cigarSessions through Pullstate
  //   const unsubscribeFromSessions = CigarStore.subscribe(
  //     s => s.sessions,
  //     (sessions) => {
  //       if (sessions[cigarId]) {
  //         setCigarSessions(sessions[cigarId]);
  //       }
  //       if (sessions.users[userId]) {
  //         setUserSessions(sessions.users[userId]);
  //       }
  //     }
  //   );
  //   return unsubscribeFromSessions();
  // }, []);

  const showProductDetail = (product) => {
    if (product && product.id) {
      console.log('Passing product:');
      console.log(product);
      // FIXME This one is throwing an error about price.toFixed(2) when just passing `product` - I think it's a `Product` object
      props.history.push({
        pathname: `/products/${hashids.encode(product.id)}`,
        state: {
          product: JSON.parse(JSON.stringify(product)),
        },
      });
    }
  };

  const shareCigar = () => {
    ShareIntent.share(new ShareItem({
      title: cigar.full_name,
      path: 'cigar',
      route: `/cigars/${hashids.encode(cigar.id)}`,
      image: metaImage || previewImageUrl(),
    }));
  };

  const toggleTab = (event, index) => {
    setState({
      ...state,
      activeTab: index,
    });
  };

  const toggleSessionModal = () => {
    const { auth } = props;
    const user = auth.user || {};
    console.log('Toggling modal state...');
    if (user && user.id) {
      // FIXME Rather than simply doing this on mobile (this works for desktops), it might be a better idea to push the
      //  route to ...?action=smokenow or whatever to trigger it being shown - this way, the back button on Android will
      //  work as it should
      setShowSessionModal(!showSessionModal);
    } else {
      redirectAuth(`${window.location.href}?action=smokenow&itemId=${cigar.id}`);
    }
  };

  const closeSessionModal = () => {
    console.log('Closing modal state...');
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('action');
    urlParams.delete('itemId');
    urlParams.delete('editId');
    props.history.replace({
      pathname: window.location.pathname,
      search: urlParams.toString(),
    });
    setShowSessionModal(false);
  };

  const toggleMoreOptions = () => {
    setState({
      ...state,
      showMoreOptions: !state.showMoreOptions,
    });
  };

  const toggleHumidorEntryEditor = () => {
    const { auth } = props;
    const user = auth.user || {};
    if (user && user.id) {
      setShowHumidorEntryEditor(!showHumidorEntryEditor);
    } else {
      redirectAuth(`${window.location.href}?action=add&itemId=${cigar.id}`);
    }
  };

  const toggleSaved = () => {
    const { auth } = props;
    const { user } = auth;

    if (user) {
      // FIXME Need to know whether it's already on the user's try list or not
      console.log('Toggling saved item for state:');
      if (itemSaved) {
        console.log('unsave');
        console.log(cigar);
        unsaveItem(user, {
          item_id: cigar.id,
          type: 'cigar',
          collection_type: 'try_list',
        });
      } else {
        console.log('save');
        saveItem(user, 'cigar', cigar, 'try_list');
      }

      props.addToast({
        content: (<>{itemSaved ? 'Removed from Try List' : 'Saved to Try List'}</>),
        duration: 6000,
      });

      setItemSaved(!itemSaved);
    } else {
      redirectAuth(`${window.location.href}?action=save`, false);
    }
  };

  const toggleFavorite = () => {
    const { auth } = props;
    const { user } = auth;

    if (user) {
      if (itemFavorited) {
        unsaveItem(user, { item_id: cigar.id, type: 'cigar', collection_type: 'favorites' });
      } else {
        saveItem(user, 'cigar', cigar, 'favorites');
      }

      props.addToast({
        content: (<>{itemFavorited ? 'Removed from Favorites' : 'Added to Favorites'}</>),
        duration: 6000,
      });

      setItemFavorited(!itemFavorited);
    } else {
      redirectAuth(`${window.location.href}?action=favorite`, false);
    }
  };

  const closeHumidorEntryEditor = () => {
    setShowHumidorEntryEditor(false);
  };

  const renderImage = (url) => (
    <FancyBox
      key={url}
      tagName="a"
      className="rui-gallery-item"
      href={url}
      closeExisting
      popupClassName="rui-popup"
      galleryId="profile-gallery"
    >
      <img
        src={url.replace('/session/', '/session/200x200/').replace('/cigars/', '/cigars/200x200/')}
        style={{
          height: 228,
          margin: 5,
          borderRadius: 5,
        }}
      />
    </FancyBox>
  );

  const renderImages = () => {
    // console.log('Rendering images...');
    // console.log(images);
    const imageUrls = [];
    const lazyImageUrls = [];
    const thumbnailImageUrls = [];
    for (const url in images) {
      imageUrls.push({ src: url }); // FIXME This supports video now, as well
      lazyImageUrls.push({ src: Resize.size(url, { height: 100, width: 100, cropType: 'crop' }) });
      thumbnailImageUrls.push({ src: Resize.thumbnail(url, { cropType: 'crop' }) });
    }
    if (imageUrls.length) {
      if (isMobileOnly) {
        return (
          <div style={{ marginTop: 16 }}>
            <MediaGrid
              media={imageUrls}
              lazyImages={lazyImageUrls}
              thumbnailMedia={thumbnailImageUrls}
              direction="horizontal"
              countFrom={4}
              fancyBox
            />
          </div>
        );
      }
      return (
        <div style={{ marginBottom: 30, marginTop: 10 }}>
          {/* FIXME This doesn't handle video */}
          <Carousel {...carouselSettings}>
            {
              imageUrls.map((media) => renderImage(media.src))
            }
          </Carousel>
        </div>
      );
    }
    return null;
  };

  const renderVitolaOptions = (allowAdding = false) => {
    if (isMobile) {
      return (
        <div
          style={{
            // height: 50,
            overflowX: 'scroll',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            zIndex: 1001,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          className="horizontal-chips-wrapper vitola-chips"
        >
          <div
            style={{
              margin: 5,
              display: 'inline-block',
            }}
          >
            <Chip
              color={!selectedVitola ? 'primary' : 'default'}
              label="All"
              onClick={() => setSelectedVitola(null)}
            />
          </div>
          {vitolas.map((vitola) => (
            <div
              style={{
                margin: 5,
                display: 'inline-block',
              }}
            >
              <Chip
                color={selectedVitola && selectedVitola.id === vitola.id ? 'primary' : 'default'}
                label={vitola.formatted_name}
                onClick={() => setSelectedVitola(vitola)}
              />
            </div>
          ))}
        </div>
      );
    }
    return (
      <Select
        value={selectedVitola}
        onChange={(e) => {
          // INFO This is to set the filter for the cigar details based on vitola
          console.log('Set new vitola...');
          console.log(e);
          // const vitola = { ...} // TODO Build based on the value/label combo? Include both in the vitola object? Or separate state object?
          setSelectedVitola(e.target.value);
        }}
        selectedMenuItemStyle={{ color: '#d5c196' }}
      >
        {vitolas.map((vitola) => (
          <MenuItem key={`vitola-${vitola.id}`} value={vitola.id}>{vitola.formatted_name}</MenuItem>
        ))}
        {allowAdding && <MenuItem key="vitola-add" value={-1}>Add Size / Shape</MenuItem>}
      </Select>
    );
  };

  const renderMoreOptionsSheet = () => (
    <BottomSheet
      items={moreOptions}
      open={state.showMoreOptions}
      toggle={toggleMoreOptions}
    />
  );

  // FIXME This only works if we pull all their sessions in - right now, that might be fine since users may not have more than 30 sessions for a given cigar
  const userSessionAvgRating = () => {
    if (userSessions) {
      let sum = 0;
      let count = 0;
      userSessions.forEach((session) => {
        if (session.advance_rating) {
          sum += session.advance_rating.rating;
          count += 1;
        }
      });
      return sum / count;
    }
    return 0;
  };

  const renderUserSessions = () => {
    if (userSessions && userSessions.length) {
      return (
        <Card
          id="user-sessions"
          style={{
            marginTop: 20,
          }}
        >
          <div style={{ margin: '16px 30px' }}>
            <h4
              style={{
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {'My Session Notes'}
            </h4>
            <p>
              {'My Average Rating:'}
              <Rating
                initialRating={userSessionAvgRating()}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                readonly
                style={{
                  marginLeft: 10,
                  color: 'rgb(214, 194, 144)',
                  fontSize: 14,
                }}
              />
            </p>
          </div>
          <div className="simple-carousel">
            {!selectedVitola && userSessions.map((session) => (
              <SessionNotesCard session={session} />
            ))}
            {selectedVitola && userSessions.filter((session) => session.vitola && session.vitola.id === selectedVitola.id).map((session) => (
              <SessionNotesCard session={session} />
            ))}
            {/* TODO No sessions indicator for selectedVitola with no sessions? */}
          </div>
        </Card>
      );
    }
    return null;
  };

  const renderDetails = () => (
    <Card
      id="cigar-details"
      style={{
        marginTop: 20,
        padding: 30,
      }}
    >
      {cigar.manufacturer && (
        <div>
          <h4
            style={{
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <FormattedMessage id="manufacturer" />
          </h4>
          <span className="text-center">{cigar.manufacturer}</span>
        </div>
      )}
      <h4
        style={{
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <FormattedMessage id="origin" />
      </h4>
      <span className="text-center">{cigar.origin || 'Unknown'}</span>
      <h4
        style={{
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <FormattedMessage id="strength" />
      </h4>
      <CigarStrengthGauge value={cigar.strength} />
      <Row>
        <Col xs={7}>
          <CigarCompositionChart.Legend cigar={cigar} />
        </Col>
        <Col xs={5} style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex' }}>
            <CigarCompositionChart cigar={cigar} />
          </div>
        </Col>
      </Row>
    </Card>
  );

  const renderAverageRatings = () => (
    <div>
      <span
        style={{
          position: 'absolute',
          right: 20,
          top: isMobileOnly ? 0 : -26,
          fontSize: '3.8em',
        }}
      >
        {detailedRatings.avg_rating ? (detailedRatings.avg_rating * 20).toFixed(0) : '-'}
      </span>
      <span>Appearance</span>
      <RatingProgress
        variant="determinate"
        value={detailedRatings.avg_appearance ? detailedRatings.avg_appearance * 20 : 0}
      />
      <span>Draw</span>
      <RatingProgress variant="determinate" value={detailedRatings.avg_draw ? detailedRatings.avg_draw * 20 : 0} />
      <span>Burn</span>
      <RatingProgress variant="determinate" value={detailedRatings.avg_burn ? detailedRatings.avg_burn * 20 : 0} />
      <span>Flavor</span>
      <RatingProgress variant="determinate" value={detailedRatings.avg_flavor ? detailedRatings.avg_flavor * 20 : 0} />
    </div>
  );

  const ratingValue = (value, totalRatings) => ((value && totalRatings) ? (value / totalRatings) * 100 : 0);

  const renderTotalRatings = () => {
    const {
      total_ratings,
      five_star_ratings,
      four_star_ratings,
      three_star_ratings,
      two_star_ratings,
      one_star_ratings,
    } = detailedRatings;
    return (
      <div>
        <Rating
          initialRating={5}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(five_star_ratings, total_ratings)} />
        <Rating
          initialRating={4}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(four_star_ratings, total_ratings)} />
        <Rating
          initialRating={3}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(three_star_ratings, total_ratings)} />
        <Rating
          initialRating={2}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(two_star_ratings, total_ratings)} />
        <Rating
          initialRating={1}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          readonly
          style={{ color: 'rgb(214, 194, 144)' }}
        />
        <RatingProgress variant="determinate" value={ratingValue(one_star_ratings, total_ratings)} />
      </div>
    );
  };

  const renderProducts = () => {
    if (isMobileOnly) {
      return (
        <Card
          style={{
            marginBottom: 20,
            paddingTop: 20,
            marginTop: 8,
          }}
        >
          <div
            id="cigar-products-card"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              display: 'flex',
              width: '100%',
            }}
          >
            <h5
              style={{
                marginBottom: 0,
                width: 'auto',
                flex: 1,
              }}
            >
              {messages[language]?.where_to_buy || 'Where To Buy'}
            </h5>
            {products.length > 8 && <Link to={`/shop/products?cigar_id=${cigar.hash_id}`}>View All</Link>}
          </div>
          <div className="simple-carousel">
            {products.slice(0, 8).map((product) => (
              <div style={{ minWidth: 200 }}>
                <ProductCardList
                  key={product.id}
                  item={product}
                  orientation="vertical"
                  hideDescription
                  hideHeader
                />
              </div>
            ))}
          </div>
        </Card>
      );
    }
    if (!products || (products && products.length === 0)) {
      return (
        <div
          style={{
            minHeight: isMobile ? 400 : 'auto',
            maxHeight: isMobile ? 'auto' : 400,
            textAlign: 'center',
          }}
        >
          {/* TODO 'No products' image on mobile */}
          <div style={{ margin: 20 }}>No products available</div>
        </div>
      );
    }
    console.log(products);
    const externalProducts = false; // FIXME If not Sigaro Smoke Shop and not selling through us, then true - just redirect to website
    return (
      <List>
        {products.map((product) => (
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <span onClick={() => showProductDetail(product)}>
                <Avatar alt={product.getDisplayName()} src={product.getMainImage()} />
              </span>
            </ListItemAvatar>
            <span onClick={() => showProductDetail(product)}>
              <ListItemText
                style={{ paddingRight: 36 }}
                primary={`${Product.title(product)}${Product.packageTitle(product).length ? ` - ${Product.packageTitle(product)}` : ''}`}
                secondary={(
                  <>
                    <div
                      style={{
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <Rating
                        initialRating={product.average_rating / 20}
                        emptySymbol="far fa-star"
                        fullSymbol="fas fa-star"
                        fractions={2}
                        readonly
                        style={{
                          color: 'rgb(214, 194, 144)',
                          marginRight: 6,
                        }}
                      />
                      <span>{`${product.total_ratings || 0} ratings`}</span>
                    </div>
                    {`Sold by ${product.venue ? product.venue.name : 'Sigaro Smoke Shop'}`}
                  </>
                )}
              />
            </span>
            <ListItemSecondaryAction>
              {product.salePrice ? (
                <button
                  className={`btn ${externalProducts ? 'btn-outline-success' : 'btn-success'} boxpressd-add-item`}
                  style={{
                    minWidth: 72,
                    display: 'inline',
                  }}
                  data-item-id={hashids.encode(product.id)}
                  data-item-price={Product.formatDecimal(product.salePrice)}
                  data-item-url={`${config.shopEndPoint}/products/${hashids.encode(product.id)}/verify`}
                  data-item-description={product.description}
                  data-item-image={Product.getImage(product)}
                  data-item-name={Product.title(product)}
                >
                  {Product.formatPrice(product)}
                </button>
              ) : null}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderSessionModal = () => {
    let entry;
    if (props.location && props.location.state && props.location.state.entry) {
      entry = props.location.state.entry;
    }
    // FIXME If there are multiple cigars in either the same or multiple humidors, we should be able to pull them in for
    //  user to pick from even if not included in the query params - if only one available, skip selection and set entry
    //  details on session - should use reselect to pull matching cigar ID entries from humidors' contents
    return (
      <SessionEditor
        cigar={cigar}
        vitola={(entry && entry.vitola) || selectedVitola}
        purchaseLocation={entry && entry.bought_from}
        purchasePrice={entry && entry.price}
        purchasePriceType={entry && entry.price_type}
        purchasePriceQty={entry && entry.price_qty}
        giftedFrom={entry && entry.gifted_from}
        open={showSessionModal}
        toggle={toggleSessionModal}
        onClose={closeSessionModal}
        onSave={() => {
          const urlParams = new URLSearchParams(window.location.search);
          const entryId = urlParams.get('entryId');
          // FIXME This shouldn't call all again - Pullstate should be handling this now
          // props.requestCigarSessions(cigar.id, 1);
          if (entryId) {
            props.updateEntry({
              id: entryId,
              qty: parseInt(urlParams.get('entryQty'), 10) - 1,
              scan: {
                user_id: props.auth.user.id,
              },
              humidor_id: urlParams.get('humidorId'),
            });
          }
        }}
      />
    );
  };

  const renderHumidorEntryEditor = () => (
    <HumidorEntryEditor
      cigar={cigar}
      open={showHumidorEntryEditor}
      toggle={toggleHumidorEntryEditor}
      onClose={closeHumidorEntryEditor}
    />
  );

  const renderReportModal = () => {
    if (isMobile) {
      console.log('Passing in cigar to editor:');
      console.log(cigar);
      return (
        <CigarEditor
          cigar={cigar}
          open={showReportModal}
          toggle={() => setShowReportModal(!showReportModal)}
          onClose={() => setShowReportModal(false)}
          requireImage={false}
        />
      );
    }
    return null; // TODO Desktop modal
  };

  const { activeTab } = state;
  const urlParams = new URLSearchParams(window.location.search);
  const { settings } = props;

  // console.log(cigar);

  const updateMeta = (sessions) => {
    if (sessions && sessions.length > 0 && !updatedMeta) {
      updatedMeta = true;
      // FIXME Why was this in here? Might be useful for the image, but not the rest
      // const urlParams = new URLSearchParams(window.location.search);
      // const sessionId = urlParams.get('sessionId');
      // const selectedSessionId = hashids.decode(sessionId);
      // sessions.forEach((session) => {
      //   if (parseInt(session.id) === parseInt(selectedSessionId)) {
      //     if (session.comment) {
      //       setMetaDescription(session.comment);
      //     }
      //     let foundImage = false;
      //     if (session.media && session.media.length) {
      //       for (let i = 0; i < session.media.length; i++) {
      //         const m = session.media[i];
      //         if (m.media_type === 'image') {
      //           // setMetaImage(`${config.mediaEndPoint}/meta/images/thumbnails/${m.media_url}`);
      //           setMetaImage(Resize.size(m.media_url));
      //           foundImage = true;
      //           break;
      //         }
      //       }
      //     }
      //     if (!foundImage) {
      //       if (session.scan && session.scan.image_url) {
      //         // setMetaImage(`${config.mediaEndPoint}/meta/images/thumbnails/${session.scan.image_url}`);
      //         setMetaImage(Resize.size(session.scan.image_url));
      //       }
      //     }
      //     // FIXME Can we get it to scroll down to the session?
      //   }
      // });
      setMetaReviews(sessions && sessions.map((session) => ({
        user: session.scan.user,
        advance_rating: session.advance_rating,
      })));
    }
  };

  const previewDescription = () => {
    if (cigar.description && cigar.description.length > 0) {
      return cigar.description;
    }
    return `${cigar.full_name} is a ${Cigar.formattedStrength(cigar.strength)} strength cigar crafted in ${cigar.origin}. ${detailedRatings.total_ratings && detailedRatings.avg_rating ? `It has a ${detailedRatings.avg_rating}/5.0 average rating on Boxpressd from ${detailedRatings.total_ratings} users.` : 'View now on Boxpressd.'}`;
  };

  const previewImageUrl = () => {
    const imageUrl = Cigar.getBandImage(cigar, false);
    if (imageUrl) {
      return `https://aouikjkrpo.cloudimg.io/v7/${imageUrl}?w=200&h=200&func=fit&bg_img_fit=1&bg_blur=10&bg_opacity=0.8`;
    }
    return undefined;
  };

  const metaProps = {
    type: 'Product',
    title: `${cigar.full_name} Cigar`,
    description: previewDescription(),
    imageUrl: previewImageUrl(),
    ratingCount: detailedRatings.total_ratings || null,
    avgRating: detailedRatings.avg_rating || null,
    reviews: metaReviews,
    offers: products && products.map((product) => ({
      sale_price: product.salePrice || product.sale_price || product.price || product.msrp,
      quantity: product.quantity,
      url: `https://${window.location.hostname}/products/${hashids.encode(product.id)}`,
    })),
  };

  if (isMobileOnly) {
    return (
      <PageWrap style={{ paddingBottom: insetBottom }}>
        <PageMeta {...metaProps}>
          {renderImages()}
          <Card
            style={{
              marginTop: 40,
              marginBottom: 20,
            }}
          >
            {hasDailySession ? (
              <Button
                id="smoke-now-btn"
                style={{
                  margin: '-20px 20px 5px',
                  textAlign: 'center',
                  display: 'inline-block',
                  backgroundColor: darkMode ? '#24262A' : '#ffffff',
                }}
                color="success"
                outline
                size="lg"
                onClick={() => {
                  props.history.push({
                    pathname: `/cigars/${hashids.encode(cigar.id)}`,
                    search: `action=smokenow&itemId=${cigar.id}&editId=${selectedSession.id}`,
                    state: { session: selectedSession },
                  });
                  // toggleSessionModal();
                }}
              >
                {'Edit Smoke Session'}
              </Button>
            ) : (
              <Button
                id="smoke-now-btn"
                style={{
                  margin: '-20px 20px 5px',
                  textAlign: 'center',
                  display: 'inline-block',
                }}
                color="success"
                size="lg"
                onClick={() => {
                  if (urlParams.get('entryId')) {
                    const currentCount = urlParams.get('entryQty');
                    const humidorName = urlParams.get('humidorName') || null; // FIXME Why is this not working? '...left in undefined'
                    const remainingCount = currentCount - 1;

                    let title;
                    if (remainingCount === 0) {
                      if (humidorName) {
                        title = `This is your last ${cigar.full_name} left in ${humidorName}`;
                      } else {
                        title = `This is your last ${cigar.full_name} left in this humidor`;
                      }
                    } else if (humidorName) {
                      title = `You have ${remainingCount} ${cigar.full_name}'s left in ${humidorName}`;
                    } else {
                      title = `You have ${remainingCount} ${cigar.full_name}'s left in this humidor`;
                    }

                    props.addToast({
                      content: (<>{title}</>),
                      duration: 6000,
                    });
                  }
                  toggleSessionModal();
                }}
              >
                <FormattedMessage id="smoke_now" />
              </Button>
            )}
            <div style={{ margin: '20px 12px 5px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar src={Cigar.getBandImage(cigar)} className="avatar-contained">
                  <img src={darkMode ? PlaceholderDark.band : Placeholder.band} style={{ height: 40, width: 40 }} />
                </Avatar>
                <div style={{ margin: 'auto 8px' }}>
                  {/* FIXME What is this line included for? Meta maybe? Old code? don't think we need it... */}
                  <h1
                    style={{
                      fontSize: 18,
                      display: 'none',
                    }}
                  >
                    {cigar.full_name}
                  </h1>
                  <Link to={`/brands/${hashids.encode(cigar.brand_id)}`}><div style={{ fontSize: 12 }} id="cigar-brand">{cigar.brand}</div></Link>
                  <h1 style={{ fontSize: 18 }} id="cigar-name">{cigar.name}</h1>
                </div>
              </div>
              <div className="divider" />
              <div
                style={{
                  float: 'right',
                  marginRight: 10,
                  marginTop: 5,
                }}
              >
                <Button outline onClick={toggleSaved} style={{ padding: 8 }}>
                  <Icon name={itemSaved ? 'bookmark' : 'bookmark-border'} vendor="material" style={{ marginRight: 6 }} />
                  {itemSaved ? strings.unsave : strings.save}
                </Button>
                <Button outline onClick={toggleFavorite} style={{ padding: 8 }}>
                  <Icon name={itemFavorited ? 'star' : 'star-border'} vendor="material" style={{ marginRight: 6 }} />
                  {itemFavorited ? strings.unfavorite : strings.favorite}
                </Button>
                <Button
                  outline
                  onClick={shareCigar}
                  style={{ padding: 8 }}
                >
                  <Icon name={isIOS ? 'share' : 'share2'} style={{ marginRight: 6 }} />
                  {strings.share}
                </Button>
                <Button outline onClick={toggleMoreOptions} style={{ padding: 8 }}>
                  <Icon name="more-horizontal" style={{ marginRight: 6 }} />
                </Button>
              </div>
            </div>
          </Card>
          <span style={{ marginLeft: 10 }}>Filter Vitola</span>
          {renderVitolaOptions()}
          {products && products.length > 0 && renderProducts()}
          {renderUserSessions()}
          <Card
            style={{
              padding: 20,
              marginTop: 20,
            }}
          >
            <h5>Average User Rating</h5>
            <div>
              <Rating
                initialRating={detailedRatings.avg_rating}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{
                  color: 'rgb(214, 194, 144)',
                  marginRight: 6,
                }}
              />
              <span>{`${detailedRatings.total_ratings || 0} ratings`}</span>
            </div>
            {renderAverageRatings()}
          </Card>
          {renderDetails()}
          <div id="cigar-extra-tabs">
            <h4 style={{ marginTop: 10, marginLeft: 12, marginBottom: 0 }}>Smoke Sessions</h4>
            <Tabs
              value={activeTab}
              onChange={toggleTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="fullWidth"
              aria-label="Cigar Additional Details"
            >
              <Tab label="Everyone" />
              <Tab label="Your Friends" />
              <Tab label="You" />
            </Tabs>
          </div>
          <div>
            <TabPanel value={activeTab} index={0}>
              <CigarSessions selectedVitola={selectedVitola} onSessionsLoaded={(sessions) => updateMeta(sessions)} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              {props.auth && props.auth.user && (
                <CigarSessions friendsOnly selectedVitola={selectedVitola} />
              )}
              {(!props.auth || !props.auth.user) && (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    onClick={() => redirectAuth()}
                    style={{ margin: '20px auto', width: '80%', maxWidth: 300 }}
                  >
                    {'Log In'}
                  </Button>
                </div>
              )}
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              {props.auth && props.auth.user && (
                <CigarSessions currentUserOnly selectedVitola={selectedVitola} />
              )}
              {(!props.auth || !props.auth.user) && (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    onClick={() => redirectAuth()}
                    style={{ margin: '20px auto', width: '80%', maxWidth: 300 }}
                  >
                    {'Log In'}
                  </Button>
                </div>
              )}
            </TabPanel>
          </div>

          {showSessionModal && renderSessionModal()}
          {state.showMoreOptions && renderMoreOptionsSheet()}
          {showHumidorEntryEditor && renderHumidorEntryEditor()}
          {showReportModal && renderReportModal()}
        </PageMeta>
      </PageWrap>
    );
  }
  return (
    <PageWrap>
      <PageMeta {...metaProps}>
        <div className="rui-profile row vertical-gap" style={{ margin: -30 }}>
          <Row style={{ width: '100%' }}>
            <Col md={8}>
              <div>
                {renderImages()}
                <Card
                  style={{
                    padding: 30,
                    maxWidth: 608,
                    margin: 'auto',
                  }}
                >
                  <Row style={{ backgroundColor: settings.night_mode ? '#24262a' : '#ffffff' }}>
                    <Col
                      sm={12}
                      style={{
                        borderBottom: '1px solid #eeeeee',
                        marginBottom: 20,
                      }}
                    >
                      {/* TODO Vitola select dropdown to the right */}
                      <div style={{ float: 'left' }}>
                        <Link to={`/brands/${hashids.encode(cigar.brand_id)}`}><div style={{ fontSize: 12 }} id="cigar-brand">{cigar.brand}</div></Link>
                        <h1 style={{ maxWidth: 250, fontSize: 18 }}>{cigar.name}</h1>
                      </div>
                      <Button
                        id="add-to-action"
                        style={{
                          float: 'right',
                          textAlign: 'center',
                          marginTop: -12,
                          marginLeft: 10,
                        }}
                        color="default"
                        size="lg"
                        onClick={(e) => {
                          setAddActionBtn(e.currentTarget);
                          setShowActionPopover(true);
                        }}
                      >
                        <Icon name="plus" />
                      </Button>
                      <Popover
                        id="add-to-action-actions"
                        open={showActionPopover}
                        anchorEl={addActionBtn}
                        onClose={() => {
                          setAddActionBtn(null);
                          setShowActionPopover(false);
                        }}
                      >
                        <div
                          style={{ padding: 10, cursor: 'pointer' }}
                          onClick={() => {
                            setShowActionPopover(false);
                            toggleHumidorEntryEditor();
                          }}
                        >
                          {'Add to Virtual Humidor'}
                        </div>
                        <div
                          style={{ padding: 10, cursor: 'pointer' }}
                          onClick={toggleSaved}
                        >
                          {itemSaved ? 'Remove from Try List' : 'Save to Try List'}
                        </div>
                        <div
                          style={{ padding: 10, cursor: 'pointer' }}
                          onClick={toggleFavorite}
                        >
                          {itemFavorited ? 'Remove from Favorites' : 'Mark as Favorite'}
                        </div>
                      </Popover>
                      <Button
                        style={{
                          float: 'right',
                          textAlign: 'center',
                          marginTop: -12,
                          marginLeft: 10,
                        }}
                        color="default"
                        size="lg"
                        onClick={shareCigar}
                      >
                        <Icon name="share2" />
                      </Button>
                      {hasDailySession ? (
                        <Button
                          id="smoke-now-btn"
                          style={{
                            float: 'right',
                            textAlign: 'center',
                            marginTop: -12,
                            backgroundColor: darkMode ? '#24262A' : '#ffffff',
                          }}
                          color="success"
                          outline
                          size="lg"
                          onClick={() => {
                            props.history.push({
                              pathname: `/cigars/${hashids.encode(cigar.id)}`,
                              search: `action=smokenow&itemId=${cigar.id}&editId=${selectedSession.id}`,
                              state: { session: selectedSession },
                            });
                          }}
                        >
                          {'Edit Smoke Session'}
                        </Button>
                      ) : (
                        <Button
                          id="smoke-now-btn"
                          style={{
                            float: 'right',
                            textAlign: 'center',
                            marginTop: -12,
                          }}
                          color="success"
                          size="lg"
                          onClick={toggleSessionModal}
                        >
                          <FormattedMessage id="smoke_now" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ backgroundColor: settings.night_mode ? '#24262a' : '#ffffff' }}>
                    <Col md={6} style={{ borderRight: '1px solid #eeeeee' }}>
                      <h5>Average User Rating</h5>
                      {renderAverageRatings()}
                    </Col>
                    <Col md={6}>
                      <h5>
                        {`Average Rating Based On ${detailedRatings.total_ratings || 0} Ratings`}
                      </h5>
                      {renderTotalRatings()}
                    </Col>
                  </Row>
                </Card>
                <CigarSessions selectedVitola={selectedVitola} onSessionsLoaded={(sessions) => updateMeta(sessions)} />
              </div>
            </Col>
            <Col md={4}>
              {renderDetails()}
              <Card
                style={{
                  marginTop: 20,
                  padding: 30,
                  position: 'sticky',
                  top: 100,
                }}
              >
                <h4>Where To Buy</h4>
                {renderProducts()}
              </Card>
            </Col>
          </Row>
        </div>

        {showSessionModal && renderSessionModal()}
        {showHumidorEntryEditor && renderHumidorEntryEditor()}
        {showReportModal && renderReportModal()}
      </PageMeta>
    </PageWrap>
  );
}

function mapStateToProps(state, props) {
  const { match: { params } } = props;
  let { cigarId } = params;
  if (cigarId.indexOf('?') !== -1) {
    cigarId = cigarId.split('?')[0];
  }
  cigarId = cigarId.replace(/[\W_]+/g, '');
  const auth = state.get('auth').toJS();
  return {
    auth,
    settings: state.get('settings'),
    userSessions: auth && auth.user ? selectAllUserSmokeSessions(hashids.decode(cigarId), auth.user.id, 1) : [],
    // INFO cigarSessions are pulled into its own CigarSessions component
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToast: (data) => dispatch(actionAddToast(data)),
  updateEntry: (entry) => dispatch(updateEntryAction(entry)),
  requestCigarSessions: (cigarId, page, callback) => dispatch(requestCigarSessionsAction(cigarId, page, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CigarDetail));
